<template>
  <div class="wrap-content">
    <div class="header margin-20">
      <div class="return">
        <div class="return-icon" @click="handleBack">
          <Icon type="ios-arrow-back" />
        </div>
        <span>数据目录</span>
        <span>/ 详情</span>
      </div>
      <!-- <ul>
          <li
            :class="navName == item.value ? 'active' : ''"
            v-for="(item, i) in nav"
            :key="i"
            :id="'info' + i"
          >
            <a href="javascript:void(0)" @click="goAnchor(item.value)">{{
              item.title
            }}</a>
          </li>
        </ul> -->
      <div class="button">
        <!-- <Button class="btn-simple" @click="handleExport">导出</Button> -->
        <CommonHeader />
      </div>
    </div>
    <div class="banner">
      <img
        src="../../assets/img/重要签.png"
        alt=""
        class="important-icon"
        v-if="cache.level == '重要数据'"
      />
      <img
        src="../../assets/img/一般签.png"
        alt=""
        class="important-icon"
        v-if="cache.level == '一般数据'"
      />
      <div class="banner-content">
        <h2>{{ formBaisc.dataName }}</h2>
        <ul>
          <li>
            <span>所属机构：</span><span>{{ cache.submitInst }}</span>
          </li>
          <li>|</li>

          <li>
            <span>填报人：</span><span>{{ cache.submitter }}</span>
          </li>
          <li>|</li>
          <li>
            <span>提交时间：</span><span>{{ cache.dataTime }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="main-content">
        <div class="info" id="basic">
          <div class="title margin-20">
            <img src="../../assets/img/已通过-基本信息.png" alt="" />
            <span>基本信息</span>
          </div>
          <div class="form">
            <Form
              ref="formBaiscValidate"
              :model="formBaisc"
              :label-width="250"
              label-position="left"
            >
              <FormItem label="数据名称" prop="dataName">
                <div>{{ formBaisc.dataName }}</div>
              </FormItem>
              <FormItem label="数据类别" prop="dataType">
                <div v-for="(item, i) in formBaisc.dataType" :key="i">
                  <span v-for="(each, index) in item" :key="index">
                    <span v-if="index != item.length - 1"
                      >{{ each }}&nbsp;/&nbsp;</span
                    >
                    <span v-else>{{ each }}</span>
                  </span>
                </div>
              </FormItem>
              <FormItem label="数据描述" prop="description">
                <div>{{ formBaisc.description }}</div>
              </FormItem>
              <FormItem label="拟定等级" prop="level">
                <div>{{ formBaisc.level }}</div>
              </FormItem>
              <FormItem label="数据量（单位：GB）">
                <div>{{ formBaisc.dataSize }}</div>
              </FormItem>
              <FormItem label="数据记录数">
                <div>{{ formBaisc.dataCount }}</div>
              </FormItem>
              <FormItem label="数据载体类型" prop="payload">
                <div>{{ formBaisc.payload }}</div>
              </FormItem>
              <FormItem label="信息系统名称" prop="appName">
                <div>{{ formBaisc.appName }}</div>
              </FormItem>
              <FormItem label="所在数据中心" prop="dataCenter">
                <div>{{ formBaisc.dataCenter }}</div>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <BackTop :bottom="100"></BackTop>
    <Record />
  </div>
</template>
    <script setup>
import {
  reactive,
  ref,
  watch,
  onMounted,
  nextTick,
  onBeforeUnmount,
  inject,
} from "vue";
import province from "@/utils/province";
import { Message, Modal } from "view-ui-plus";
import Footer from "@/components/footer.vue";
import reject from "./components/reject.vue";
import { useRouter, useRoute } from "vue-router";
import CommonHeader from "@/components/commonHeader.vue";
import DownFiles from "@/utils/dowFile";
import bus from "@/utils/bus";
import Record from "./components/record.vue";
let id = ref("");
let level = ref(0);
let cache = ref({}); //当前数据
let router = useRouter();
let route = useRoute();
const handleBack = () => {
  router.back();
};

//验证数据是否填写完整
let formBaisc = ref({
  dataName: "",
  dataType: [],
  description: "",
  level: "一般数据",
  dataSize: "",
  dataCount: "",
  payload: "",
  appName: "",
  dataCenter: "",
});
let axios = inject("axios");

//导出
const handleExport = () => {
  let url = `/content/infoExport?dataId=${id.value}`;
  axios.get(url, { responseType: "blob" }).then((res) => {
    // console.log(res);
    if (res.status == 200) {
      const blob = res.data;
      // 获取文件名
      const content = res.headers["content-disposition"];
      const fileName = content && content.split(";")[1].split("filename=")[1];
      DownFiles(blob, fileName);
    }
  });
};

onMounted(() => {
  let query = route.query;
  if (query.id) {
    id.value = query.id;
    level.value = query.level;
    getDataDetails();
    getAuditDate();
    // getOperateCount();
  }
});
//查询暂存详情
const getDataDetails = () => {
  let url = `/content/generalInfo?dataId=${id.value}`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      for (let key in data) {
        if (data[key] == "" || data[key] == null) {
          data[key] = "无";
        }
      }
      cache.value = data;

      id.value = data.id;
      formBaisc.value = {
        dataName: data.dataName,
        dataType: data.dataType,
        description: data.description,
        level: "一般数据",
        dataSize: data.dataSize.toString(),
        dataCount: data.dataCount.toString(),
        payload: data.payload,
        appName: data.appName,
        dataCenter: data.dataCenter,
        first: data.first,
      };
    }
  });
};
let auditThroughTime = ref("");
//查询审核通过时间
const getAuditDate = () => {
  let url = `/content/auditInfo?dataId=${id.value}`;
  axios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      auditThroughTime.value = res.data.data;
    }
  });
};
//点击查看审批记录
const handleRecord = () => {
  bus.emit("openHistory", id.value);
};

//查询操作记录总条数
let operateCount = ref(0);
const getOperateCount = () => {
  let url = `/content/operateCount?dataId=${id.value}`;
  axios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      operateCount.value = res.data.data;
    }
  });
};

onBeforeUnmount(() => {});
</script>
<style lang="scss" scoped>
@import "./details.scss";
</style>   
